import { setupLayouts } from 'virtual:meta-layouts'
import generatedRoutes from 'virtual:generated-pages'
import type { RouteRecordRaw } from 'vue-router'
import StatisticsUser from './modules/user.regional_distribution'
import AccessAuthority from './modules/access_authority'
import System from './modules/system'
import organize from './modules/organize'
import iot from './modules/iot'
import WatchFaces from './modules/watch_faces'
import User from './modules/user'
import Customer from './modules/customer'
import StatisticsIot from './modules/iot.overview'
import Sdk from './modules/sdk'
import type { Route } from '#/global'
import useSettingsStore from '@/store/modules/settings'

// 免登录路由
// 固定路由（默认路由）
const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      whiteList: true,
      title: '登录',
      i18n: 'route.login',
    },
  },
  {
    path: '/:all(.*)*',
    name: 'notFound',
    component: () => import('@/views/[...all].vue'),
    meta: {
      title: '找不到页面',
    },
  },
]

// 系统路由
const systemRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/index.vue'),
    meta: {
      title: () => useSettingsStore().settings.home.title,
      breadcrumb: false,
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/index.vue'),
        meta: {
          title: () => useSettingsStore().settings.home.title,
          i18n: 'route.home',
          icon: 'ant-design:home-twotone',
          breadcrumb: false,
        },
      },
      {
        path: 'reload',
        name: 'reload',
        component: () => import('@/views/reload.vue'),
        meta: {
          title: '重新加载',
          breadcrumb: false,
        },
      },
      {
        path: 'personal/setting',
        name: 'personalSetting',
        component: () => import('@/views/personal/setting.vue'),
        meta: {
          title: '个人设置',
          i18n: 'route.personal.setting',
          cache: 'personalEditPassword',
        },
      },
      {
        path: 'personal/edit/password',
        name: 'personalEditPassword',
        component: () => import('@/views/personal/edit.password.vue'),
        meta: {
          title: '修改密码',
          i18n: 'route.personal.editpassword',
        },
      },
      {
        path: 'personal/notification',
        name: 'personalNotification',
        component: () => import('@/views/personal/notification.vue'),
        meta: {
          title: '通知中心',
          i18n: 'route.personal.notification',
        },
      },
    ],
  },
]

// 动态路由（异步路由、导航栏路由）
const asyncRoutes: Route.recordMainRaw[] = [
  // {
  //   meta: {
  //     title: '演示',
  //     icon: 'uim:box',
  //   },
  //   children: [
  //     MultilevelMenuExample,
  //     BreadcrumbExample,
  //   ],
  // },
  {
    meta: {
      title: '管理',
      icon: 'carbon:gui-management',
    },
    children: [
      AccessAuthority,
      System,
      organize,
      iot,
      WatchFaces,
      User,
      Customer,
      Sdk,
    ],
  },
  {
    meta: {
      title: '统计报表',
      icon: 'system-uicons:graph-bar',
    },
    children: [
      StatisticsUser,
      StatisticsIot,
    ],
  },
  {
    meta: {
      title: '数据大屏',
      icon: 'system-uicons:graph-bar',
    },
    children: [
      {
        path: '/statistics/full_screen/user/hm_overview/:id',
        name: 'StatisticsFullScreenUserHmOverview',
        component: () => import('@/views/full_screen/user.health.overview.vue'),
        meta: {
          title: '用户健康关爱',
          icon: 'bx:world',
          sidebar: false,
          // breadcrumb: false,
        },
      },{
        path: '/statistics/full_screen/overview_of_users_and_devices',
        name: 'StatisticsFullScreenOverviewOfUsersAndDevices',
        component: () => import('@/views/full_screen/operation.overview.vue'),
        meta: {
          title: '运营数据',
          icon: 'bx:world',
          // sidebar: false,
          // breadcrumb: false,
        },
      },
    ],
  },
]

const constantRoutesByFilesystem = generatedRoutes.filter((item) => {
  return item.meta?.enabled !== false && item.meta?.constant === true
})

const asyncRoutesByFilesystem = setupLayouts(generatedRoutes.filter((item) => {
  return item.meta?.enabled !== false && item.meta?.constant !== true && item.meta?.layout !== false
}))

export {
  constantRoutes,
  systemRoutes,
  asyncRoutes,
  constantRoutesByFilesystem,
  asyncRoutesByFilesystem,
}
